.loadingBG{

  display: flex;
  justify-content: center;
  align-items: center;
  z-index:9999;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  transition: opacity .15s linear;
}
.text-loading,
.dropdown-menu > li > a.text-loading {
	color: #1db8ff !important;
	width: 4rem;
	height: 4rem;
}
.loadingBG2{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
    border-right-color: currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}