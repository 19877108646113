.mobile_wrapper {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	background-color: rgb(255, 255, 255);
	background-color: rgba(255, 255, 255, 1);
	overflow-x: hidden;
	transition: 0.5s;
	scrollbar-width: none;
}

.mobile_wrapper__content {
	position: relative;
	top: 5%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	box-sizing: border-box;
}

.mobile_wrapper::-webkit-scrollbar {
	display: none;
}
.mobile_item-admin{
	width: 90%;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #004282;
	margin: 8px 0;
	padding: 24px;
	border-radius: 20px;
	cursor: pointer;
	box-sizing: border-box;
	transition: 0.3s;
	background: #004282;
	color: #fff;
	font-weight: bold
}
.mobile_item {
	width: 60%;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #004282;
	margin: 8px 0;
	padding: 18px;
	border-radius: 4px;
	cursor: pointer;
	box-sizing: border-box;
	transition: 0.3s;
}

.mobile_item:hover {
	background-color: #f5f6ff;
}

.mobile_item:hover * {
	color: #0708138f !important;
}

.mobile_closebtn {
	position: absolute;
	top: 3%;
	right: 12px;
	background: none;
	color: #888ba5;
	border: none;
	font-size: 50px;
	cursor: pointer;
	transition: 0.2s ease-in-out;
}

.mobile_closebtn:hover {
	color: black;
}

.mobile_item__text {
	font-size: 16px;
	color: black;
	font-weight: 600;
	text-decoration: none;
}

.mobile_item button {
	border: 2px solid #ff5c5c;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 100px;
	padding: 6px 12px;
	cursor: pointer;

	font-family: DM Sans;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	text-align: center;
	color: #000000;
}

@media screen and (max-width: 450px) {
	.mobile_item {
		width: 90%;
	}
}
